// import lib
import { lazy } from "react";

const webRoutes = [
  {
    path: "/activation-required",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(/* webpackChunkName: "activation" */ "./../components/activation")
    ),
  },
  {
    path: "/register",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "register" */ "./../components/register")
    ),
  },
  {
    path: "/upaep-ai-coach",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "selfRegistration-upaep" */ "./../components/selfRegistration/upaep")
    ),
  },
  {
    path: "/utbis-ai-coach",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "selfRegistration-utbis" */ "./../components/selfRegistration/utbis")
    ),
  },
  {
    path: "/utp-ai-coach",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "selfRegistration-utp" */ "./../components/selfRegistration/utp")
    ),
  },
  {
    path: "/itssmt-ai-coach",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "selfRegistration-itssmt" */ "./../components/selfRegistration/itssmt")
    ),
  },
  {
    path: "/simplified-recruitment",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "selfRegistration-simplifiedRecruitment" */ "./../components/selfRegistration/simplifiedRecruitment")
    ),
  },
  {
    path: "/story-up",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "selfRegistration-story-up" */ "./../components/selfRegistration/storyUp")
    ),
  },
  {
    path: "/you-study",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "selfRegistration-you-study" */ "./../components/selfRegistration/youStudy")
    ),
  },
  {
    path: "/aurora",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "selfRegistration-aurora" */ "./../components/selfRegistration/aurora")
    ),
  },
  {
    path: "/genu",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "selfRegistration-genu" */ "./../components/selfRegistration/genu")
    ),
  },
  {
    path: "/app-privacy-policy/:id",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "whitelabel-appPrivacyPolicy" */ "./../components/whitelabel/privacyPolicy")
    ),
  },
  {
    path: "/app-terms-and-condition/:id",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "whitelabel-appTermsCondition" */ "./../components/whitelabel/termsCondition")
    ),
  },
];

export default webRoutes;
